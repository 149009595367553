import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import SignUp from '../components/SL/SignUp'
import withContentfulBlocks from '../components/withContentfulBlocks'

class PharmacyIndex extends React.Component {
  render () {
    const jobs = get(this.props, 'data.jobs.edges')
    const slJobs = jobs.filter(post => post.node.showOnSites.includes('sl'))

    return (
      <div>
        <ContentfulPage
          blocks={this.props.blocks}
          footerColor='white'
          jobs={slJobs}
          siteName={get(this.props, 'data.site.siteMetadata.siteName')}
          pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Deliver with Shelf Life`}
          pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/careers`}
        >
          <SignUp />
        </ContentfulPage>
      </div>
    )
  }
}

export default withContentfulBlocks(PharmacyIndex)

export const pageQuery = graphql`
  query SLPharmacyQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "4C510AK79Ktf2dRnHc3F7T" }) {
      ...PageBlocksFragment
    }
    jobs: allContentfulJobPosting(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          category
          teaser {
            childMarkdownRemark {
              html
            }
          }
          slug
          location
          showOnSites
        }
      }
    }
  }
`
